const Gallery = () => import("../Gallery/Gallery.vue");
const Services = () => import("../Services/Services.vue");
const Booking = () => import("../Booking/Booking.vue");
const Contacts = () => import( "../Contacts/Contacts.vue");
const Dashboard = () =>import("../Dashboard/Dashboard.vue");
const SwitchLocale = () => import("../SwitchLocale/SwitchLocale.vue");
const PriceList = () => import("../PriceList/PriceList.vue");

export default {
    components: {SwitchLocale, Gallery, Services, Booking, Contacts, Dashboard, PriceList},
    name: 'App',
    created() {
        this.getPriceList();
        this.getContactDetails();
    },
    methods: {
        getPriceList() {
            let that = this;
            let url = `/pricelist/${this.$i18n.locale}`;
            this.$http().get(url)
              .then((response) => {
                  that.priceList = response.data;
              })
        },
        clearBooking() {
            this.$refs.booking_ref.clearTimer();
        },
        getContactDetails() {
            const that = this;
            const url = `/contacts`;
            this.$http().get(url)
              .then((response) => {
                  console.log(response.data);
                  that.contacts = response.data;
              })
        }
    },
    data() {
        return {
            title: 'Paluküla puhkemaja',
            showBooking: false,
            showGallery: false,
            priceList: [],
            contacts: [],
        }
    },
}
