export default {
    props: ['card_title', 'image', 'contacts'],
    computed: {
        email() {
            return this.contacts.email;
        },
        phone() {
            return this.contacts.phone;
        }
    }
}
